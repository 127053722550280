<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑采购员' : '创建采购员'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入"
            clearable
            :disabled="isChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password" v-if="!isChange">
          <el-input
            v-model.trim="form.data.password"
            maxlength="11"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 超级管理员选地区 -->
        <el-form-item label="所在地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-radio-group v-model="form.data.role">
            <el-radio :label="1">管理员</el-radio>
            <el-radio :label="2">员工</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'BuyerEdit',
    data() {
      return {
        roleType: getStorage('role_type'),
        login_province: getStorage('province'),
        login_city: getStorage('city'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            account: '',
            password: '',
            area: '',
            role: '',
          },
          rules: {
            name: [{ required: true, message:'请输入名称', trigger: 'change'}],
            account: [
              { required: true, message:'请输入联系电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            password: [{ required: true, message:'请输入密码', trigger: 'change'}],
            area: [{ required: true, message:'请选择地区', trigger: 'change'}],
            role: [{ required: true, message:'请选择角色', trigger: 'change'}],
          }
        },
        areaArr: [], 
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.area = [row.province, row.city]
          })
        }
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                  delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              account: this.form.data.account,
              password: this.form.data.password,
              province: this.roleType == 1 ? this.form.data.area[0] : this.login_province,
              city: this.roleType == 1 ? this.form.data.area[1] : this.login_city,
              role: this.form.data.role
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/purchase/edit' : '/admin/purchase/add'
            // return
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      
    }
  }
</script>

