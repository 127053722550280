<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    width="500px"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      element-loading-text="加载中…"
      ref='ruleForm'
      label-width='100px'
      label-position='right'
      :model='ruleForm'
      :rules='rules'
    >
      <el-form-item label="新密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click='toggle(false)'>取 消</el-button>
      <el-button type="primary" :loading="loading" :disabled="loading" @click='confirm'>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'NewPwd',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      loading: false,
      curRow: {},
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    getDetail(row) {
      this.curRow = row;
    },
    confirm() {
      this.$refs.ruleForm.validate(valid => {
        if(valid) {
          this.loading = true;
          this.$http.post('/admin/purchase/setPassword', {id: this.curRow.id, password: this.ruleForm.pass}).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.toggle(false)
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    reset(done) {
      this.$refs.ruleForm.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>